import { atom, selectorFamily } from 'recoil';
import { ICategories, ILanguage } from '../services/api.services';
import { getLocation, getState } from '../utils/products';

export interface IGallery {
  main: boolean;
  document: {
    _id: string;
    file: {
      filename: string;
    };
    tags: string[];
  };
}

export interface IAttributeChoices {
  name: ILanguage;
  value: string;
}

export interface IAttributeChild {
  name: ILanguage;
  choices: IAttributeChoices[];
  key: string;
  _id: string;
}

export interface IAttribute {
  name: ILanguage;
  choices: IAttributeChoices[];
  key: string;
  _id: string;
  value: string | number;
  attribute: IAttribute;
}

export interface IVariant {
  product: IProducts;
}

export interface IPlanObj {
  image?: string[];
  dorms?: string;
  bath?: string;
  id?: string;
}

export interface IProducts {
  _id: string;
  name: ILanguage;
  categories: ICategories[];
  gallery: IGallery[];
  description: ILanguage;
  attributes: IAttribute[];
  variants: IVariant[];
  price?: number;
  createdAt: string;
}

export type IFilters = Record<'location' | 'operation' | 'unit-type' | 'state', { _id?: string; value: string }>;

export const productsState = atom({
  key: 'productsState',
  default: [] as IProducts[],
});

export const languageState = atom({
  key: 'languageState',
  default: 'US',
});

export const availableLangsState = atom({
  key: 'availableLangsState',
  default: ['US'],
});

export const defaultLangState = atom({
  key: 'defaultLangState',
  default: 'US',
});

export const variantsState = atom({
  key: 'variantsState',
  default: [] as IProducts[],
});

export const categoriesState = atom({
  key: 'categoriesState',
  default: [] as ICategories[],
});

export const filtersState = atom({
  key: 'filtersState',
  default: {} as IFilters,
});

export const selectProductById = selectorFamily({
  key: 'selectProductById',
  get:
    (id: string | undefined) =>
    ({ get }) => {
      const products = get(productsState);
      return products.find((p) => p._id === id || (id && Object.values(p.name).some(ln => new RegExp(ln.replace(/\s/ig, '-'), 'i').test(id as string))));
    },
});

export const filterProducts = (products: IProducts[], filters: IFilters) => {
  return filterProductsByCategories(products, filters.operation)
    .filter((p) => !filters.location || filterProductByLocation(p, filters.location))
    .filter((p) => !filters.state || filterProductByState(p, filters.state))
    .filter((p) => !filters['unit-type'] || filterProductByApartments(p, filters['unit-type']));
};

export const filterProductsByCategories = (products: IProducts[], category: IFilters['operation']) => {
  return products.filter((p: IProducts) => {
    return !category || p.categories.some((c) => c._id === category?.value);
  });
};

export const filterProductByLocation = (product: IProducts, location: IFilters['location']) => {
  return !location || !!new RegExp(location?.value, 'i').test(getLocation(product.attributes)?.value as string);
};

export const filterProductByState = (product: IProducts, state: IFilters['state']) => {
  return !state || !!new RegExp(state?.value, 'i').test(getState(product.attributes)?.value as string);
};

export const filterProductByApartments = (product: IProducts, unitType: IFilters['unit-type']) => {
  return (
    !unitType ||
    !!product.variants.filter((v) =>
      v.product.attributes.find((a) => {
        return (
          (typeof a.attribute === 'object' ? a.attribute.key === 'unit-type' : a.attribute === unitType._id) &&
          `${a.value}` === `${unitType.value}`
        );
      })
    ).length
  );
};
