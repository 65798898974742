export const defaultTranslations = {
    applyNow: 'Apply now',
    units: 'UNITS',
    aboutUs: 'ABOUT US',
    modenaHousing: 'Modena Housing',
    modenaHousingAbout1: 'At <strong>Modena Housing</strong>, we are more than just developers; we are builders of modern and vibrant communities. Our journey began in sunny Miami, Florida with a simple yet ambitious goal: to redefine the landscape of modern real estate development.',
    modenaHousingAbout2: "Our team is comprised of seasoned professionals possessing a wealth of experience in the industry, with a focus on multifamily rentals. United by our shared passion for excellence as well as a commitment to shaping spaces that inspire. We strive to bring innovation, quality, and a fresh perspective to every project we undertake. ",
    modenaHousingAbout3: "What sets us apart is our dedication to understanding the unique needs and desires of our residents. We believe that a home is more than just four walls – it's a reflection of one's lifestyle, aspirations, and dreams. That's why we go above and beyond to create living spaces that exceed expectations, blending contemporary design with thoughtful details to offer a truly exceptional living experience.",
    modenaHousingAbout4: "Although it may not be easily apparent from the outside our work extends far beyond construction; it's about making personal connections, building communities, and leaving a lasting legacy. From the moment we first break ground to the final unveiling, we approach each project with integrity, transparency, and an unwavering commitment to providing a world class product.",
    modenaHousingAbout5: "Join us on this journey as we continue to push boundaries and reshape the future of real estate development",
    vision: 'VISION',
    vision1: 'Under the visionary leadership of our founders, Alejandro Ambrugna and Alejandro Garcia, our mission at Modena Housing is to go beyond mere construction; we are dedicated to fostering lively communities that stand the test of time. Focusing on innovation, sustainability, and inclusivity, we aspire to redefine contemporary living by crafting spaces that resonate with the diverse needs and aspirations of our residents. Drawing inspiration from the core values instilled by our founders, we seek to establish new benchmarks in real estate development, creating environments where individuals thrive, connect, and grow.',
    vision2: "Positioned strategically in emerging neighborhoods, Modena Housing's focus on market-rate rentals and mixed-use properties is driven by our dedication to serving the community. Our projects not only enhance the local environment but also significantly improve the quality of life for all residents in the surrounding area. Through collaborative efforts and creative ingenuity, we endeavor to leave a profound and lasting impact on the neighborhoods we serve.",
    whatWeDo: "WHAT WE DO TO GET <br />THE BEST RESULTS:",
    constructionManagement: "Construction Management",
    constructionManagementDescription: "Modena Constructions, an integral division of our company, takes pride in overseeing the construction of our projects. Partnering directly with architects, engineers, subcontractors, and suppliers to ensure the highest quality and efficiency. Our team leverages our company's growing presence in the real estate sector, offering insightful analysis, strategic recommendations, and access to top-notch personnel.",
    propertyManagement: 'Property Management',
    propertyManagementDescription: "Modena Housing stands out as a rising star in the industry, distinguished by our hands-on approach to managing our properties. Specializing in multifamily apartment communities, we prioritize excellence in marketing, training, financial reporting, maintenance, and construction management. Our close collaboration with the development team ensures seamless integration, creating exceptional living environments and delivering optimal performance for our properties.",
    stayInContact: "Stay in contact",
    send: 'Send',
    sendSuccess: 'Email sent successfully',
    sendError: 'There was an error sending the message. Please try again',
    interestedIn: "I'm interested in",
    lookingFor: "I'm looking for",
    location: "Location",
    availability: 'Availability',
    underConstruction: 'Under construction',
    leasing: 'Currently leasing',
    coming: 'Coming soon',
    apartments: "Apartments",
    oneBedroom: 'One bedroom',
    twoBedroom: 'Two bedroom',
    threeBedroom: 'Three bedroom',
    townhouses: 'Townhouses',
    explorePortfolio: 'Explore Modena’s Portfolio',
    inspiring: 'INSPIRING PROJECTS FOR INSPIRED LIVING',
    inspiringDetails: 'Discover the perfect rental experience with our exceptional properties. From stylish urban apartments to private communities, each residence offers the ideal blend of comfort, convenience, and quality.',
    about: 'About',
    properties: 'Properties',
    contact: 'Contact us',
    apply: "Apply now",
    residents: 'Residents',
    seeMore: 'See more',
    comingSoonLabel: '<p>COMING<br />SOON</p>',
    underConstructionLabel: '<p>UNDER<br />CONSTRUCTION</p>',
    leasingLabel: '<p>CURRENTLY<br />LEASING</p>',
    review1: `Our commitment to meticulous detail and dedication to excellence continue to drive positive change, revitalizing neighborhoods. We remain steadfast in our pursuit of developing properties that enhance cities and improve lives.”`,
    review2: `Modena Housing was founded with a resolute commitment to enhancing the lives of both our residents and the communities surrounding our buildings."`,
    all: 'All',
    viewAllFloorPlans: 'View all floor plans'
}