import styled from 'styled-components';
import review from '../imgs/living.png';
import LogoSmall from '../imgs/favicon.png';
import { FunctionComponent } from 'react';
import { Box } from '@mui/material';
import ScrollAnimation from 'react-animate-on-scroll';
import { useTranslation } from 'react-i18next';

const ReviewsContainer = styled.div`
    background-image: url(${review});
    width: 100vw;
    background-size: cover;
`;

export interface IReview {
    text: string;
    by: string;
    position: string;
};


const ReviewsContent = styled(Box)`
    width: 100%;
    height: 100%;
    background-color: ${(p) => p.theme.colors.darkOpacity};
    text-align: center;
`;

const StyledLogoSmall = styled.img`
    width: 70px;
`;

export const Reviews = () => {
    const {t} = useTranslation();
    const reviews: IReview[] = [{
        text: t('review1'),
        by: `Alejandro Ambrugna`,
        position: `Co-Founder`
    }, {
        text: t('review2'),
        by: `Alejandro Garcia`,
        position: `Co-Founder`
    }];
    return (
        <ReviewsContainer>
            <ReviewsContent sx={{
                padding: {
                    md: '5rem 20vw',
                    xs: '2rem 1rem'
                },
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
            }}>
                <StyledLogoSmall src={LogoSmall} />
                {reviews.map(r => <Review {...r} />)}
            </ReviewsContent>
        </ReviewsContainer>
    );
}

const ReviewContainer = styled.div`
    color: white;
`;
const ReviewText = styled.p`
    font-style: italic;
    font-weight: bold;
`;
const ReviewBy = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    p:first-child{
        font-weight: bold;
    }
`;

export const Review: FunctionComponent<IReview> = ({ text, by, position }) => {
    return (
        <ReviewContainer>
            <ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
                <ReviewText>{text}</ReviewText>
                <ReviewBy>
                    <p>{by}</p>
                    <p>|</p>
                    <p>{position}</p>
                </ReviewBy>
            </ScrollAnimation>
        </ReviewContainer>
    );
}