import { Box, FormControl } from '@mui/material';
import { FunctionComponent, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { theme } from '../App';
import { useAttributes, useCategories } from '@wbsuite/react-hooks';
import { ICategories, IResponse } from '../services/api.services';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import { CustomSelect, Items } from './Select';
import { useRecoilState, useRecoilValue } from 'recoil';
import { categoriesState, filtersState, IFilters, languageState } from '../states/state';
import { useLocation } from 'react-router';
import AnimateHeight from 'react-animate-height';
import { getValueByLang } from '../utils/products';
import { useTranslation } from 'react-i18next';

const FilterContainer = styled(Box)`
  gap: 20px;
  background-color: white;
  padding: 20px;
  box-shadow: ${(p) => p.theme.shadow.primary};
  * {
    flex: 1;
  }
  svg {
    fill: ${(p) => p.theme.colors.primary};
    color: ${(p) => p.theme.colors.primary};
  }
  label,
  .Mui-focused,
  .MuiInputBase-formControl {
    fill: ${(p) => p.theme.colors.gold};
    color: ${(p) => p.theme.colors.gold};
  }
  .MuiInput-root {
    border-bottom: 1px solid ${(p) => p.theme.colors.primary};
  }
  .MuiInput-root:hover:not(.Mui-disabled, .Mui-error):before {
    border-bottom: 2px solid ${(p) => p.theme.colors.primary};
  }
  .MuiSelect-select:focus {
    background-color: transparent;
  }
`;

const FilterWrapper = styled(Box)`
  width: 100%;
`;

const FilterArea = styled(Box)``;

const FilterMoreBox = styled(Box)`
  gap: 10px;
  span {
    flex: none;
  }
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: ${(p) => p.theme.colors.primary};
  cursor: pointer;
`;

const FilterMore = styled(Box)`
  gap: 10px;
  svg,
  span {
    flex: none;
  }
  color: ${(p) => p.theme.colors.primary};
  cursor: pointer;
`;

const mapToItem = (array: any[], lang: string) => {
  return array.map((c) => ({
    label: getValueByLang(c.name, lang),
    value: c._id,
  }));
};

export const Filters: FunctionComponent<{ showMore?: boolean }> = ({ showMore }) => {
  const [expanded, setExpanded] = useState(false);
  const useCategoriesHook = useCategories();
  const { getGrouped } = useAttributes() as any;
  const [categories, setCategories] = useRecoilState<ICategories[]>(categoriesState);
  const [types, setTypes] = useState<Items[]>([]);
  const [filters, setFilters] = useRecoilState(filtersState);
  const [availableFilters, setAvailableFilters] = useState<
    Record<string, { _id: string; name: Record<string, string>; options: { label: Record<string, string>; value: unknown }[]}>
  >({});
  const pageLocation = useLocation();
  const toggleExpanded = () => setExpanded(!expanded);
  const lang = useRecoilValue(languageState);
  const { t } = useTranslation();

  const resetFilters = () => setFilters({} as IFilters);

  useEffect(() => {
    if (Object.keys(filters).length) resetFilters();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageLocation.pathname]);

  useEffect(() => {
    getGrouped({ key: 'filters' })
      .then((response: IResponse) => {
        const attributes = response?.data?.[0]?.attributes ?? [];
        setAvailableFilters(
          attributes.reduce((acc: any, filter: any) => {
            acc[filter.key] = {
                _id: filter._id,
                name: filter.name,
                options: filter.choices.map((c: any) => ({ label: c.name, value: c.value }))
            };
            return acc;
          }, {})
        );
      })
      .catch((e: any) => console.log(e));
    useCategoriesHook
      .list()
      .then((response: IResponse) => setCategories(response?.data))
      .catch((e) => console.log(e));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setTypes(
      mapToItem(
        categories.filter((c) => !c.parent),
        lang
      )
    );
  }, [categories, lang]);

  const handleFilterChange = (prop: keyof IFilters, attributeId?: string) => (newValue: unknown) => {
    setFilters(f => {
        const { [prop]: thisFilter, ...others } = f;
        return { ...others, ...(typeof newValue === 'undefined' ? {} : { [prop]: { _id: attributeId, value: newValue } }) } as IFilters;
    })
  }

  const mainFilters = useMemo(() => {
    return (
      <>
        <Filter
          label={t('lookingFor')}
          value={filters.operation?.value}
          handleChange={handleFilterChange('operation', availableFilters.operation?._id)}
          items={types}
        />
        <Filter
          label={t('location')}
          value={filters.location?.value}
          handleChange={handleFilterChange('location', availableFilters.location?._id)}
          items={availableFilters.location?.options}
        />
      </>
    );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters, availableFilters, lang, t, types]);

  const extraFilters = useMemo(() => {
    return (
      <FilterWrapper
        sx={{
          display: 'grid !important',
          gridTemplateColumns: {
            xs: 'repeat(1, 1fr)',
            md: 'repeat(2, 1fr)',
          },
        }}
      >
        <Filter
          label={t('availability')}
          value={filters.state?.value}
          handleChange={handleFilterChange('state', availableFilters.state?._id)}
          items={availableFilters.state?.options}
        />

        <Filter
          label={t('apartments')}
          value={filters['unit-type']?.value}
          handleChange={handleFilterChange('unit-type', availableFilters['unit-type']?._id)}
          items={availableFilters['unit-type']?.options}
        />
      </FilterWrapper>
    );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [availableFilters, filters]);

  return (
    <FilterContainer
      sx={{
        width: {
          xs: '95vw',
          md: '60vw',
        },
      }}
    >
      <FilterWrapper
        sx={{
          display: 'grid !important',
          gridTemplateAreas: {
            xs: '"filters" "second-filters" "expand"',
            md: '"filters expand" "second-filters expand"'
        },
          gridTemplateColumns: {
            xs: '1fr',
            md: '.7fr .3fr',
          },
        }}
      >
        <FilterWrapper
          sx={{
            display: 'grid !important',
            gridArea: 'filters',
            gridTemplateColumns: {
              xs: 'repeat(1, 1fr)',
              md: 'repeat(2, 1fr)',
            },
          }}
        >
          {mainFilters}
        </FilterWrapper>
        <FilterArea sx={{ displayArea: 'second-filters' }}>
          <AnimateHeight height={expanded ? 'auto' : 0} duration={500}>
            {extraFilters}
          </AnimateHeight>
        </FilterArea>
        {showMore && (
          <FilterMoreBox
            sx={{
              gridArea: 'expand',
              borderLeft: {
                xs: '0px',
                md: `2px solid ${theme.colors.primary}`,
              },
              flex: {
                xs: '1',
                md: '0.5',
              },
              marginLeft: {
                sx: '0',
                md: '20px'
              }
            }}
          >
            <FilterMore onClick={toggleExpanded} sx={{ display: 'flex', alignItems: 'center' }}>
              <span>{expanded ? 'Less' : 'More'}</span>
              {expanded ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
            </FilterMore>
            {expanded ? (
              <FilterMore onClick={resetFilters}>
                <span>{t('Reset')}</span>
              </FilterMore>
            ) : (
              <></>
            )}
          </FilterMoreBox>
        )}
      </FilterWrapper>
    </FilterContainer>
  );
};

export interface IFilter {
  label: string;
  value?: string;
  handleChange: (value: any) => void;
  items: Items[];
}

export const Filter: FunctionComponent<IFilter> = ({ value, handleChange, items, label }) => {
  return (
    <FormControl variant='standard' sx={{ m: 1, minWidth: 120 }}>
      <CustomSelect
        value={value}
        onChange={handleChange}
        label={label}
        defaultValue=''
        items={items}
      />
    </FormControl>
  );
};
