import { Box, Typography } from "@mui/material";
import styled from "styled-components";
import Img from '../imgs/whatWeDo.png';
import bg from '../imgs/about_bg_2.png';
import { theme } from "../App";
import ScrollAnimation from "react-animate-on-scroll";
import { useTranslation } from "react-i18next";

const WhatWeDocontainer = styled.div`
    background-image: url(${bg});
    background-size: cover;
`;
const WhatWeDoTitle = styled(Typography)`
    color: ${(p) => p.theme.colors.primary};
`;
const WhatWeDoContent = styled(Box)`
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    gap: 10rem;
    img{
        width: 40%;
    }
    p{
        font-weight: 500;
    }
`;
const WhatWeDoText = styled.div``;


export const WhatWeDo = () => {
    const { t } = useTranslation();
    return (
        <WhatWeDocontainer>
            <ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
                <WhatWeDoTitle variant="h3" sx={{
                    padding: {
                        xs: theme.padding.responsiveTitle,
                        md: theme.padding.title
                    },
                    paddingBottom: {
                        xs: '2rem',
                        md: '10rem'
                    },
                    paddingTop: '0 !important',
                    maxWidth: '35rem',
                }}>{t('whatWeDo')}</WhatWeDoTitle>
            </ScrollAnimation>
            <WhatWeDoContent sx={{
                'img': {
                    display: {
                        xs: 'none',
                        md: 'block'
                    }
                },
                padding: {
                    xs: theme.padding.responsiveSection,
                    md: theme.padding.sectionRight
                }
            }}>
                <WhatWeDoText>
                    <ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
                        <p>
                            <strong>{t('constructionManagement')}</strong><br />
                            {t('constructionManagementDescription')}
                        </p>
                    </ScrollAnimation>
                    <br />
                    <br />
                    <ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
                        <p>
                            <strong>{t('propertyManagement')}</strong><br />
                            {t('propertyManagementDescription')}
                        </p>
                    </ScrollAnimation>
                </WhatWeDoText>
                <img src={Img} alt='' />
            </WhatWeDoContent>
        </WhatWeDocontainer>
    );
}