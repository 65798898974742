import { useProducts } from "@wbsuite/react-hooks";
import { useEffect } from "react";
import { IResponse } from "../services/api.services";
import { useRecoilState, useSetRecoilState } from "recoil";
import { IProducts, productsState, variantsState } from "../states/state";
import { getState } from "../utils/products";

const sortProducts = (prods: IProducts[]) => {
    const sortingScheme = [
        "LEASING",
        "CONSTRUCTION",
        "COMING",
    ]
    return prods.sort((a, b) => {
        const stateA = getState(a.attributes);
        const stateB = getState(b.attributes);

        const indexOfa = sortingScheme.indexOf(stateA?.value || '');
        const indexOfb = sortingScheme.indexOf(stateB?.value || '');
        if (indexOfa > indexOfb)
            return 1
        if (indexOfa < indexOfb)
            return -1
        return 0;
    })
}

export const UseGetProducts = () => {
    const api = useProducts();
    const [products, setProducts] = useRecoilState(productsState);
    const setVariants = useSetRecoilState(variantsState);

    useEffect(() => {
        if (!products.length) {
            api.list()
                .then((response: IResponse) => {
                    const varsIds = response.data.map((p: IProducts) => p.variants.map(v => v.product._id)).flat();
                    const [prods, vars] = response.data.reduce((acc, curr: IProducts) => {
                        const idx = varsIds.includes(curr._id) ? 1 : 0;
                        acc[idx].push(curr);
                        return acc;
                    }, [[], []] as [IProducts[], IProducts[]]);
                    setProducts(sortProducts(prods));
                    setVariants(vars);
                })
                .catch(e => console.log(e));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
}