import { Box, Button, CircularProgress, TextField } from "@mui/material";
import styled from "styled-components";
import { sendMessage } from "../services/api.services";
import { ChangeEvent, SetStateAction, useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import ReCAPTCHA from "react-google-recaptcha";
import { api } from "../config/config";

const ContactContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 2rem 0;
`;
const ContactTitle = styled.h3`
    text-transform: uppercase;
    font-weight: normal;
`;

const ContactForm = styled(Box)`
    background-color: ${(p) => p.theme.colors.light};
    padding: 2rem;
    flex-wrap: wrap;
`;

const ContactInputs = styled(Box)`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    *{
        flex: 1;
        color: white;
        font-weight: bold;
    }
`;

const StyledBtn = styled(Button)`
    background-color: ${(p) => p.theme.colors.yellow};
    text-align: center;
    color: white !important;
    width: 100%;
`;

const StyledTextField = styled(TextField)({
  '& label.Mui-focused': {
    color: 'white',
  },
  '& .MuiInputBase-formControl': {
    borderRadius: 0,
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: 'white',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: 'white',
    },
    '&:hover fieldset': {
      borderColor: 'white',
    },
    '&.Mui-focused fieldset': {
      borderColor: 'white',
    },
  },
});


const Message = styled.p<{ $success: boolean }>`
  width: 100%;
  text-align: center;
  padding: 5px 0;
  color: ${(p) => p.$success ? 'green' : 'red'};
`;

const StyledCircularProgress = styled(CircularProgress)`
  margin-left: 10px;
  width: 10px !important;
  height: 10px !important;
`;

const StyledReCaptcha = styled(ReCAPTCHA)`
display: flex;
justify-content: center;
align-items: center;
padding: 20px 0;
`;

export const Contact = () => {
  const [email, setEmail] = useState<string>();
  const [message, setMessage] = useState<string>();
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isFailure, setIsFailure] = useState(false);
  const { t } = useTranslation();
  const [isCaptchaValid, setIsCaptchaValid] = useState(false);

  const handleOnClick = async () => {
    setIsLoading(true);
    if (!email || !message) return;
    sendMessage({ email, message })
      .then(() => {
        setIsSuccess(true);
      })
      .catch(() => {
        setIsFailure(true);
      })
      .finally(() => setIsLoading(false));
  }

  const onChangeCaptcha = useCallback((value: any) => {
    setIsCaptchaValid(true);
  }, []);

  const hasEmailError = useMemo(() => {
    // eslint-disable-next-line
    const isValidEmail = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;
    return !!(email && !email.match(isValidEmail));
  }, [email]);

  const handleOnChange = (v: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, set: { (value: SetStateAction<string | undefined>): void; (value: SetStateAction<string | undefined>): void; (arg0: any): void; }) => {
    setIsFailure(false);
    setIsSuccess(false);
    set(v.target.value);
  }
  return (
    <ContactContainer>
      <ContactTitle>{t('stayInContact')}</ContactTitle>
      <ContactForm sx={{
        width: {
          xs: "95vw",
          md: "70vw"
        },
      }}>
        <ContactInputs sx={{
          flexDirection: {
            xs: 'column',
            md: 'row',
          },
          "div": {
            width: {
              xs: "100%",
              md: "auto"
            }
          }
        }}>
          <StyledTextField label="E-mail" variant="outlined" error={hasEmailError} onChange={(v) => handleOnChange(v, setEmail)} />
          <StyledTextField label={t('interestedIn')} variant="outlined" onChange={(v) => handleOnChange(v, setMessage)} />
        </ContactInputs>
        <StyledReCaptcha
          sitekey={api.recaptcha}
          onChange={onChangeCaptcha}
        />
        <br />
        <StyledBtn onClick={handleOnClick} disabled={!email || !message || hasEmailError || !isCaptchaValid}>
          {t('send')} {isLoading && <StyledCircularProgress color="inherit" />}
        </StyledBtn>
        {isSuccess && <Message $success={true}>{t('sendSuccess')}</Message>}
        {isFailure && <Message $success={false}>{t('sendError')}</Message>}
      </ContactForm>
    </ContactContainer>
  );
}