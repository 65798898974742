import { FunctionComponent } from "react";
import { MainLayout } from "../components/MainLayout";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { isMobile } from "react-device-detect";

const TermsWrapper = styled.div<{isMobile: boolean}>`
    padding: 1rem 3rem;
    padding-top: ${(p) => p.isMobile ? '50px' : '150px'};
`;

export const Terms: FunctionComponent = () => {
    const { t } = useTranslation();
    return (
        <MainLayout>
            <TermsWrapper isMobile={isMobile}>
                <h2>{t('terms')}</h2>
                <br />
                <div dangerouslySetInnerHTML={{ __html: t('terms-text') }} />
            </TermsWrapper>
        </MainLayout>
    );
}