import { FunctionComponent } from 'react';
import Carousel from 'react-material-ui-carousel';
import styled from 'styled-components';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { isMobile } from 'react-device-detect';

export interface ICarrouselProps {
  imgs: string[];
  autoplay?: boolean;
  main?: boolean;
  navButtonsAlwaysVisible?: boolean;
}

const StyledImg = styled.img<{isMobile?: boolean}>`
  object-fit: cover;
  width: 100%;
  height: 100%;
`;

const StyledArrowForwardIosIcon = styled(ArrowForwardIosIcon)`
  fill: ${(p) => p.theme.colors.darkOpacity};
`;

const StyledArrowBackIosNewIcon = styled(ArrowBackIosNewIcon)`
  fill: ${(p) => p.theme.colors.darkOpacity};
`;

export const Carrousel: FunctionComponent<ICarrouselProps> = ({ imgs, autoplay = true, main = true, navButtonsAlwaysVisible = false }) => {
  const height = main ? isMobile ? '25vh' : '45vw' : undefined;
  return (
    <Carousel
      autoPlay={autoplay}
      indicators={false}
      height={height}
      interval={4000}
      stopAutoPlayOnHover={false}
      NextIcon={<StyledArrowForwardIosIcon />}
      PrevIcon={<StyledArrowBackIosNewIcon />}
      navButtonsAlwaysVisible={navButtonsAlwaysVisible}
      navButtonsAlwaysInvisible={(imgs?.length || 0) <= 1}
      navButtonsProps={{
        // Move the buttons to the bottom. Unsetting top here to override default style.
        style: {
          borderRadius: 0,
          backgroundColor: 'transparent',
        },
      }}
    >
      {imgs.map((img, i) => (
        <StyledImg src={img} alt={`Slider ${i}`} key={`Slider ${i}`} isMobile={isMobile}/>
      ))}
    </Carousel>
  );
};
