import { FunctionComponent } from "react";
import { MainLayout } from "../components/MainLayout";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { isMobile } from "react-device-detect";

const DisclaimerWrapper = styled.div<{isMobile: boolean}>`
    padding: 1rem 3rem;
    padding-top: ${(p) => p.isMobile ? '50px' : '150px'};
`;

export const Disclaimer: FunctionComponent = () => {
    const { t } = useTranslation();
    return (
        <MainLayout>
            <DisclaimerWrapper isMobile={isMobile}>
                <h2>{t('disclaimer')}</h2>
                <div dangerouslySetInnerHTML={{ __html: t('disclaimer-text') }} />
            </DisclaimerWrapper>
        </MainLayout>
    );
}