import React from 'react';
import logo from '../logo.png';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

const StyledImg = styled.img`
    width: 150px;
    cursor: pointer;
`;
export const Logo = () => {
    const navigate = useNavigate();
    const handleClick = () => {
        navigate("/");
      }

    return <StyledImg src={logo} alt="Logo" onClick={handleClick}/>;
}