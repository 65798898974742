import { FunctionComponent, useEffect, useState } from 'react';
import { useDocuments } from '@wbsuite/react-hooks';
import { getImageUrl } from '../utils/products';
import slider1 from '../imgs/modena87.png';
import slider2 from '../imgs/slider2.png';
import slider3 from '../imgs/slider3.png';
import { Carrousel } from './Carrousel';

const defaultSlider = [slider1, slider2, slider3];

type Tag = string;
const imagesMapping: Record<Tag, string[]> = {};

export const MainCarrousel: FunctionComponent<{ tag: Tag }> = ({ tag }) => {
  const getDocuments = useDocuments();
  const [documents, setDocuments] = useState<string[]>(imagesMapping[tag] ?? []);
  useEffect(() => {
    async function fetchDocuments(tag: Tag) {
      const documents = await getDocuments.list({ tags: tag });
      const imagesUrl = documents.data.map((d: any) => getImageUrl(d.file.filename));
      setDocuments(imagesUrl.length ? imagesUrl : defaultSlider);
    }
    if (!documents.length) {
      fetchDocuments(tag);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return <Carrousel imgs={documents || []}></Carrousel>;
};
