import { FunctionComponent, useMemo } from 'react';
import { Carousel } from 'react-responsive-carousel';
import styled from 'styled-components';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { isMobile } from 'react-device-detect';
import { IPlanObj } from '../states/state';
import BedOutlinedIcon from '@mui/icons-material/BedOutlined';
import BathtubOutlinedIcon from '@mui/icons-material/BathtubOutlined';
export interface ICarrouselCenterMode {
  imgs?: string[];
  width?: string;
  centerSlidePercentage?: number;
  centerMode?: boolean;
  outsideArrows?: boolean;
  planImgs?: IPlanObj[];
  autoPlay?: boolean;
  infiniteScrolling?: boolean;
}

const StyledWrapper = styled.div<{ $wd?: string }>`
  width: ${(p) => p.$wd || '35vw'};
  img {
    width: 100%;
  }
  position: relative;
  &:hover {
    .hover-plan {
      opacity: 1;
    }
  }
`;

const StyledHoverPlan = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  background-color: ${(p) => p.theme.colors.darkOpacity};
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 20px;
  .icon {
    font-size: 50px;
    color: ${(p) => p.theme.colors.yellow};
  }
  div {
    display: flex;
    gap: 10px;
    span {
      font-size: 25px;
    }
  }
  opacity: 0;
  transition: ease 0.5s opacity;
`;

const StyledCarousel = styled(Carousel)<{centerMode?: boolean}>`
  .slide {
    display: flex;
    justify-content: center;
    align-items: center;
    &:not(.selected) {
      opacity:${(p) => p.centerMode ? '0.5' : '1'};
    }
  }
  margin: 50px 0;
`;

const ArrowStyle = `
    position: absolute;
    top: calc(50% - (45px / 2));
    z-index: 100;
    cursor: pointer;
`;

const StyledKeyboardArrowLeftIcon = styled(KeyboardArrowLeftIcon)<{
  $outsideArrows: boolean;
}>`
  color: ${(p: any) => p.theme.colors.darkOpacity};
  transition: color 0.5s;
  &:hover {
    color: ${(p: any) => p.theme.colors.dark};
  }
  ${ArrowStyle}
  left: ${(p) => (p.$outsideArrows ? '1%' : '5%')};
`;

const StyledKeyboardArrowRightIcon = styled(KeyboardArrowRightIcon)<{
  $outsideArrows: boolean;
}>`
  color: ${(p: any) => p.theme.colors.darkOpacity};
  transition: color 0.5s;
  &:hover {
    color: ${(p: any) => p.theme.colors.dark};
  }
  ${ArrowStyle}
  right: ${(p) => (p.$outsideArrows ? '1%' : '5%')};
`;

export const CarrouselCenterMode: FunctionComponent<ICarrouselCenterMode> = ({
  planImgs,
  outsideArrows,
  centerMode = true,
  imgs,
  width,
  centerSlidePercentage,
  infiniteScrolling = true,
  autoPlay = false,
}) => {
  const customPrevArrow = (clickHandler: () => void, hasPrev: boolean, label: string): React.ReactNode => {
    return (
      <>
        {hasPrev && (
          <StyledKeyboardArrowLeftIcon
            $outsideArrows={isMobile || !!outsideArrows}
            onClick={clickHandler}
            sx={{
              width: {
                xs: '30px',
                md: '45px',
              },
              height: {
                xs: '30px',
                md: '45px',
              },
            }}
          />
        )}
      </>
    );
  };
  const customNextArrow = (clickHandler: () => void, hasNext: boolean, label: string): React.ReactNode => {
    return (
      <>
        {hasNext && (
          <StyledKeyboardArrowRightIcon
            $outsideArrows={isMobile || !!outsideArrows}
            onClick={clickHandler}
            sx={{
              width: {
                xs: '30px',
                md: '45px',
              },
              height: {
                xs: '30px',
                md: '45px',
              },
            }}
          />
        )}
      </>
    );
  };

  const items = useMemo(() => {
    return imgs
      ? imgs.map((img, i) => {
          return (
            <StyledWrapper className='carrousel-item-wrapper' key={`Item${i}`} $wd={isMobile ? '90vw' : width}>
              <img src={img} alt={`Item${i}`} />
            </StyledWrapper>
          );
        })
      : planImgs
      ? planImgs.map((plan, i) => {
          return (
            <StyledWrapper className='carrousel-item-wrapper' key={`Item${i}`} $wd={isMobile ? '90vw' : width}>
              <img src={plan.image as unknown as string} alt={`Item${i}`} />
              <StyledHoverPlan className='hover-plan'>
                <div>
                  <BedOutlinedIcon className='icon' />
                  <span>{plan.dorms}</span>
                </div>
                <div>
                  <BathtubOutlinedIcon className='icon' />
                  <span>{plan.bath}</span>
                </div>
              </StyledHoverPlan>
            </StyledWrapper>
          );
        })
      : [];
  }, [imgs, planImgs, width]);
  return (
    <StyledCarousel
      centerMode={isMobile ? false : centerMode}
      centerSlidePercentage={centerSlidePercentage || 40}
      infiniteLoop={infiniteScrolling}
      emulateTouch={true}
      showThumbs={false}
      showStatus={false}
      renderArrowPrev={customPrevArrow}
      renderArrowNext={customNextArrow}
      showIndicators={true}
      autoPlay={autoPlay}
      stopOnHover={true}
    >
      {items}
    </StyledCarousel>
  );
};
