import React, { useEffect } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { api, config, INavigation } from './config/config';
import {
  StyledEngineProvider,
} from '@mui/material';
import { ThemeProvider } from 'styled-components';
import { useLanguages, useTranslations, useWBSuite } from '@wbsuite/react-hooks';
import { ScrollToTop } from './components/ScrollToTop';
import {
  useSetRecoilState,
} from 'recoil';
import { availableLangsState, defaultLangState } from './states/state';
import { loadTranslations } from './hooks/loadTranslations';

export const theme = {
  colors: {
    main: `#C1BEA8`,
    black: `#545152`,
    primary: '#b39e21',
    light: '#c1bea8',
    dark: `#393636`,
    yellow: "#c8ae16",
    darkOpacity: `#393636ad`,
    gold: "#C4AF3E",
    // "#C2BEA8",
    
    lightBg: '#E0DED3',
  },
  shadow: {
    primary: `0px 0px 2px 1px #0000002b`,
  },
  padding: {
    title: `2rem 10rem`,
    section: `2rem 4rem`,
    sectionRight: `2rem 0 2rem 10rem`,
    responsiveSection: '1rem 3rem',
    responsiveTitle: `3rem 5rem`,
    topTitle: `4rem 10rem`,
  },
};

function App() {
  //Modena 7Exf>0f1y83Q  admin.wbsuite.app
  useWBSuite({ apiKey: api.key });
  const languages = useLanguages();
  const setAvailableLangs = useSetRecoilState(availableLangsState);
  const setDefaultLang = useSetRecoilState(defaultLangState);
  const translations = useTranslations();
  loadTranslations();
  
  useEffect(() => {
    const getLanguages = async () => await languages.list();
    getLanguages().then((response) => {
      const langs = response.data;
      const defaultLanguage = langs.find((l: any) => l.isDefault);
      setAvailableLangs(langs.map((l: any) => l.code.toUpperCase()));
      setDefaultLang(defaultLanguage?.code);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    (translations as any).getJson().then((response: any) => {
      const translations = response.data || {};
      const langs = Object.keys(translations) || [];
      const langObj = langs.map((l) => {
        return {
          [l === 'us' ? 'en' : l]: {
            translation: translations[l], 
          }
        }
      }).reduce((acc, current) => {
        acc = {
          ...acc,
          ...current,
        };
        return acc;
      }, {});
      loadTranslations(langObj);
    }).catch((e: any) => {});
  }, [translations])

  return (
      <ThemeProvider theme={theme}>
        <StyledEngineProvider injectFirst>
          <BrowserRouter basename={process.env.PUBLIC_URL}>
            <Routes>
              {config.navigation.map((n: INavigation, i) => {
                const { path, component } = n;
                return <Route path={path} element={component} />
              })}
            </Routes>
            <ScrollToTop />
          </BrowserRouter>
        </StyledEngineProvider>
      </ThemeProvider>
  );
}

export default App;
