import styled from 'styled-components';
import { MainLayout } from '../components/MainLayout';
import { Filters } from '../components/Filters';
import { Box } from '@mui/material';
import { PropertiesCatalog } from '../components/PropertiesCatalog';
import bgImg2 from '../imgs/properties_bg_1.png';
import { Contact } from '../components/Contact';
import { MainCarrousel } from '../components/MainCarrousel';
import { isMobile } from 'react-device-detect';

const PropertiesSection = styled.section``;

const PropertiesFilterContainer = styled.div<{isMobile?: boolean}>`
    margin-top: ${(p) => p.isMobile ? '-25px': '-50px'};
    z-index: 100;
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const ContactWrapper = styled(Box)`
    background-color: ${(p) => p.theme.colors.lightBg};
`;

export const Properties = () => {
    
    return (
        <MainLayout showContact={false}>
            <PropertiesSection>
                <MainCarrousel tag='properties' />
                <PropertiesFilterContainer isMobile={isMobile}>
                    <Filters showMore={true} />
                </PropertiesFilterContainer>
                <PropertiesList />
            </PropertiesSection>
            <ContactWrapper>
                <Contact />
            </ContactWrapper>
        </MainLayout>

    );
}

export interface IProperties {
    name: string;
    location: string;
    state: 'construction' | 'leasing' | 'coming';
    img?: any;
    id: number;
    description?: string;
    imgs?: string[];
    units?: string[];
}

const BgTop = styled.img`
    width: 100%;
    position: absolute;
    top: -60px;
    z-index: -1;
    object-fit: cover;
`;
const BgBottom = styled(Box)`
    background: ${(p) => p.theme.colors.lightBg};
    background: linear-gradient(172deg, rgba(224,222,211,0) 50%, ${(p) => p.theme.colors.lightBg} 50%);
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 20rem;
    z-index: -1;
`;

const PropertiesListWrapper = styled(Box)`
    display: grid;
    gap: 50px;
    position: relative;
`;

export const PropertiesList = () => {
    return (
        <PropertiesListWrapper sx={{
            gridTemplateColumns: {
                xs: "repeat(1, 1fr)",
                md: "repeat(3, 1fr)"
            },
            padding: {
                xs: '2rem 0',
                md: `2rem 7rem`
            },
            "div": {
                justifySelf: 'center',
            }
        }}>
            <BgTop src={bgImg2} />
            <PropertiesCatalog view={'product'} />
            <BgBottom />
        </PropertiesListWrapper>
    );
}