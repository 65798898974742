import { Link } from "@mui/material";
import styled from "styled-components";
import { config } from "../config/config";
import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next";

const FooterContainer = styled.div`
    background-color: ${(p) => p.theme.colors.dark};
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 2rem;
    color: white;
    .modena-footer{
        width: 100%;
        text-align: right;
    }
`;
const FooterLinks = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    .footer-link{
        color: ${(p) => p.theme.colors.light};
    }
    
`;
const FooterWB = styled.div`
font-size: 0.8em;
`;

const StyledLink = styled(Link)`
    text-decoration: none;
    color: white;
    cursor: pointer;
`;

export const Footer = () => {
    const navItems = config.navigation.filter(n => n.title);
    const navigate = useNavigate();
    const handleClick = (path: string, external: boolean) => {
        if (external) {
            window.open(path, '_blank');
        } else {
            navigate(path);
        }
    }
    const {t} = useTranslation();
    return (
        <FooterContainer>
            <FooterLinks>
                {navItems.map(i => <StyledLink className="footer-link" onClick={() => handleClick(i.path, !i.component)}>{t(i.title || '')}</StyledLink>)}
            </FooterLinks>
            <p className="modena-footer">Modena Housing®</p>
            <FooterWB>Powered by <StyledLink href="https://webbuilders.com.ar" target="_blank">WebBuilders</StyledLink>.</FooterWB>
        </FooterContainer>
    );
}