import { MainLayout } from '../components/MainLayout';
import styled from 'styled-components';
import { Box } from '@mui/material';
import { CarrouselCenterMode } from '../components/CarrouselCenterMode';
import { theme } from '../App';
import { useRecoilValue } from 'recoil';
import { IAttribute, IProducts, languageState, selectProductById, variantsState } from '../states/state';
import { useParams } from 'react-router';
import { useCallback, useMemo, useState } from 'react';
import {
  getAddress,
  getAttributeChoiceValue,
  getFloorplans,
  getLink,
  getMainImage,
  getPropertySliderGallery,
  getPropertyUnitGallery,
  getValueByLang,
} from '../utils/products';
import logo from '../logo.png';
import { useTranslation } from 'react-i18next';
import { UnitCard } from '../components/unitCard';
import { api } from '../config/config';

const PropImgWrapper = styled.img<{ $full: boolean }>`
  width: ${(p) => (p.$full ? '100%' : '80%')};
  object-fit: cover;
  max-height: 45vw;
  max-width: ${(p) => (p.$full ? '100%' : '35rem')};
`;

const PropImgContainer = styled.div<{ $full: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${(p) => (p.$full ? '0' : '10rem 0')};
  background-color: ${(p) => p.theme.colors.dark};
`;

const PropDescription = styled(Box)``;


const StyledButton = styled.button`
  background-color: ${(p) => p.theme.colors.yellow};
  border: 1px solid white;
  color: white;
  padding: 10px;
  cursor: pointer;
  &:hover {
    background-color: white;
    color: ${(p) => p.theme.colors.yellow};
    border-color: ${(p) => p.theme.colors.yellow};
    transition: ease 0.5s;
  }
`;

const ViewAllFloorPlans = styled.div`
  a{
    width: 200px;
  }
  display:flex;
  justify-content: center;
  align-items: center;
  padding: 60px 0;
`;

export const PropertiesDetails = () => {
  let { id } = useParams();
  const lang = useRecoilValue(languageState);
  const { t } = useTranslation();

  const property: IProducts | undefined = useRecoilValue(selectProductById(id));
  const mainImgUrl = useMemo(() => getMainImage(property?.gallery), [property?.gallery]);
  const link = useMemo(() => getLink(property?.attributes), [property?.attributes]);
  const handleApply = useCallback(() => {
    if (link) {
      window.open(`${link}`, '_blank');
    }
  }, [link]);
  const variants = useRecoilValue(variantsState);

  const mainSlider = useMemo(() => getPropertySliderGallery(property?.gallery) as string[], [property?.gallery]);
  const propertyVariants = useMemo(() => {
    return property?.variants
      ?.map((v) => variants.find((va) => va._id === v.product._id))
      .filter((v) => !!v) as IProducts[];
  }, [property?.variants, variants]);

  const unitSlider = useMemo(() => getPropertyUnitGallery(property?.gallery) as unknown as string[], [property?.gallery]);
  const [imgHasError, setImageHasError] = useState(false);
  const isFullImage = useMemo(() => !!(mainImgUrl && !imgHasError), [imgHasError, mainImgUrl]);

  const floorPlans = useMemo(() => getFloorplans(property?.attributes), [property?.attributes]);
  const address = useMemo(() => getAddress(property?.attributes), [property?.attributes]);
  const map = useMemo(() => {
    // eslint-disable-next-line jsx-a11y/iframe-has-title
    return (<iframe
      width="100%"
      height="450"
      loading="lazy"
      src={`https://www.google.com/maps/embed/v1/place?key=${api.googleMapsApi}&q=${address}`}>
    </iframe>)
  }, [address]);

  return (
    <MainLayout>
      <PropImgContainer $full={isFullImage}>
        <PropImgWrapper
          $full={isFullImage}
          src={imgHasError ? logo : mainImgUrl || logo}
          onError={() => {
            setImageHasError(true);
          }}
          alt='catalog_item'
        />
      </PropImgContainer>
      <PropDescription
        sx={{
          padding: {
            xs: theme.padding.responsiveSection,
            md: theme.padding.title,
          },
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            flexDirection: {
              xs: 'column',
              md: 'row',
            },
            gap: {
              xs: '20px',
              md: 0,
            },
          }}
        >
          <div className='min-w-0 flex-1'>
            <h2 className='text-2xl font-bold leading-7 sm:truncate sm:text-3xl sm:tracking-tight text-dark'>
              {getValueByLang(property?.name, lang).toUpperCase()}
            </h2>
            <div className='mt-1 flex flex-col sm:mt-0 sm:flex-row sm:flex-wrap sm:space-x-6'>
              {property?.attributes
                .filter((attr) => !new RegExp(attr.attribute.key, 'i').test('(link|floor-plans|link_pdf)'))
                .map((attr) => {
                  return (
                    <div className='mt-2 flex items-center text-sm text-gray-500 gap-[.5rem]'>
                      <span className='font-bold'>{getValueByLang(attr.attribute.name, lang)}</span>
                      <span>{getValueByLang(getAttributeChoiceValue(attr as IAttribute)?.name, lang) || attr.value}</span>
                    </div>
                  );
                })}
            </div>
          </div>
          <div className='mt-5 flex lg:ml-4 lg:mt-0'>
            <span className='sm:ml-3'>
              <StyledButton onClick={handleApply}>{t('applyNow')}</StyledButton>
            </span>
          </div>
        </Box>
        <br />
        <div
          dangerouslySetInnerHTML={{ __html: getValueByLang(property?.description, lang) as unknown as TrustedHTML }}
        ></div>
      </PropDescription>
      {!!mainSlider?.length && <CarrouselCenterMode imgs={mainSlider} autoPlay={true} centerMode={mainSlider?.length > 1}/>}

      {!!unitSlider?.length && (
        <div className='sm:mt-5 md:mt-10'>
          <CarrouselCenterMode imgs={unitSlider} autoPlay={true} />
        </div>
      )}
      {propertyVariants?.length ? (
        <div className='bg-white'>
          <div className='mx-auto max-w-2xl px-4 py-16 sm:px-6 sm:py-24 lg:max-w-7xl lg:px-8'>
            <h2 className='text-2xl font-bold tracking-tight text-gray-900'>{t('unitTypes')}</h2>
            <div className='mt-6 grid grid-cols-1 gap-x-6 gap-y-10 sm:grid-cols-2 lg:grid-cols-3 xl:gap-x-8'>
              {propertyVariants?.map((unitType) => (
                <UnitCard unit={unitType} />
              ))}
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
      {!!floorPlans && <ViewAllFloorPlans><a
        href={floorPlans as string}
        target='_blank'
        className='mt-5 block w-full bg-gold px-3 py-2 text-center text-sm font-semibold text-white shadow-sm group-hover:opacity-75 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600'
        rel='noreferrer'
      >
        {t('viewAllFloorPlans')}
      </a></ViewAllFloorPlans>}
        <div>
          {map}
        </div>
    </MainLayout>
  );
};
