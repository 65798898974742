import { api } from "../config/config";
import { ILanguage } from "../services/api.services";
import { IAttribute, IAttributeChoices, IGallery, IPlanObj, IProducts } from "../states/state";

export const getMainImage = (gallery: IGallery[] | undefined) => {
  if (!gallery?.length) return;
  return getImageUrl(
    (gallery.find((g) => g.main) || gallery[0])?.document.file.filename
  );
};

export const getUnitImages = (gallery: IGallery[] | undefined) => {
  if (!gallery?.length) return;
  return gallery.filter(v => v.document.tags.includes('unit')).map(v => getImageUrl(v?.document?.file?.filename));
};

export const getImageUrl = (name?: string) => {
  if (!name) return undefined;
  return `${api.img}/${name}`;
};

export const getAttributeChoiceValue = (attribute: IAttribute): IAttributeChoices | undefined => {
  return attribute?.attribute.choices?.find(c => c.value === attribute?.value);
};

export const getLocation = (attributes?: IAttribute[]) => {
  const attribute = (attributes || []).find((at) => new RegExp('LOCATION', 'i').test(at.attribute.key))
  return attribute?.attribute.choices?.find(c => c.value === attribute?.value);
};

export const getFloorplans = (attributes?: IAttribute[]) => {
  const attribute = (attributes || []).find((at) => new RegExp('FLOOR-PLANS', 'i').test(at.attribute.key))
  return attribute?.value;
};

export const getAddress = (attributes?: IAttribute[]) => {
  const attribute = (attributes || []).find((at) => new RegExp('address', 'i').test(at.attribute.key));
  return getValueByLang(getAttributeChoiceValue(attribute as IAttribute)?.name, 'us');
};

export const getState = (attributes?: IAttribute[]) =>{
  const attribute = (attributes || []).find((at) => new RegExp('STATE', 'i').test(at.attribute.key));
  return attribute?.attribute.choices?.find(c => c.value === attribute?.value);
};

export const getLink = (attributes?: IAttribute[]) => {
  const attribute = (attributes || []).find((at) => new RegExp('LINK', 'i').test(at.attribute.key));
  return attribute?.value;
}

export const getPDFLink  = (attributes?: IAttribute[]) => {
  const attribute = (attributes || []).find((at) => new RegExp('LINK_PDF', 'i').test(at.attribute.key));
  return attribute?.value;
}

export const getPropertySliderGallery = (gallery?: IGallery[]) =>
  (gallery || [])
    .filter((g) => g.document.tags.includes("slider"))
    .map((g) => getImageUrl(g.document.file.filename));

export const getPropertyUnitGallery = (gallery?: IGallery[]) => {
  return (gallery || [])
    .filter((g) => g.document?.tags?.includes('unit'))
    .map((g) => getImageUrl(g.document.file.filename) as string);
};

export const getPropertyPlanObject = (variants?: IProducts[], lang?: string) => {
  return (variants || []).map((v) => {
    const dorms = v?.attributes?.find((a) => a.attribute.key === 'dorms')?.value;
    const bath = v?.attributes?.find((a) => a.attribute.key === 'bathrooms')?.value;
    return {
      image: getPropertyMapGallery(v?.gallery)[0],
      dorms,
      bath,
      id: v._id,
    };
  }).filter(v => v.image) as IPlanObj[];
};

export const getPropertyMapGallery = (gallery?: IGallery[]) =>
  (gallery || [])
    .filter((g) => g.document.tags.includes("plan"))
    .map((g) => getImageUrl(g.document.file.filename) as unknown as string[]);

export const getValueByLang = (value?: string | ILanguage, lang?: string) => {
  if(!value || !lang) return '';
  if(typeof value === 'string') return value;
  return value[lang.toLocaleLowerCase()] || value[Object.keys(value)[0]] || value[Object.keys(value)[1]];
}