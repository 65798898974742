import { FunctionComponent } from "react";
import { MainLayout } from "../components/MainLayout";
import { AboutUsSection } from "../components/AboutUs";
import { Vision } from "../components/Vision";
import { WhatWeDo } from "../components/WhatWeDo";
import { Contact } from "../components/Contact";
import styled from "styled-components";
import { Box } from "@mui/system";

const ContactContainer = styled(Box)`
    background: ${(p => p.theme.colors.dark)};
    background: linear-gradient(3.5deg, ${(p => p.theme.colors.dark)} 50%, ${(p => p.theme.colors.lightBg)} 50%);
`;

export const About: FunctionComponent = () => {
    return (
        <MainLayout showContact={false}>
            <AboutUsSection />
            <Vision />
            <WhatWeDo />
            <ContactContainer>
                <Contact />
            </ContactContainer>
        </MainLayout>
    );
}