import { Box, Typography } from "@mui/material";
import styled from "styled-components";
import Img from '../imgs/about_bg_vision.png';
import { theme } from "../App";
import ScrollAnimation from "react-animate-on-scroll";
import { useTranslation } from "react-i18next";

const VisionTitle = styled(Typography)`
    padding: ${(p) => p.theme.padding.title};
    padding-bottom: 0;
`;
const VisionText = styled.p`

`;
const VisionContainer = styled(Box)`
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    background-image: url(${Img});
    background-size: cover;
    background-position: bottom;
    color: white;
`;


const ContentContainer = styled(Box)`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`;


export const Vision = () => {
    const Content = () => {
        const { t } = useTranslation();
        return (
            <ContentContainer sx={{
                flexDirection: {
                    xs: 'column',
                    md: 'row'
                },
                padding: {
                    xs: theme.padding.responsiveSection,
                    md: theme.padding.topTitle,
                }
            }}>
                <VisionTitle variant="h2">{t('vision')}</VisionTitle>
                <ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
                    <VisionText>
                        <p>
                        {t('vision1')}
                        </p>
                        <p>
                        {t('vision2')}
                        </p>
                    </VisionText>
                </ScrollAnimation>
            </ContentContainer>
        )
    }
    return (
        <VisionContainer sx={{
            minHeight: {
                xs: '50rem',
                md: '35rem',
            }
        }}>
             <Content />
        </VisionContainer>
    );
}