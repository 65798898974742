import { FunctionComponent } from "react";
import styled from 'styled-components';
import { HomeCatalog } from "../components/HomeCatalog";
import { Reviews } from "../components/Reviews";
import { MainLayout } from "../components/MainLayout";
import { MainCarrousel } from "../components/MainCarrousel";

const HomeContainer = styled.div``;

export const Home: FunctionComponent = () => {
    return (
        <MainLayout>
            <HomeContainer>
                <MainCarrousel tag='mainSlider' />
                <HomeCatalog />
                <Reviews />
            </HomeContainer>
        </MainLayout>
    );
}