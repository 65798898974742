import styled from "styled-components";
import { Contact } from "./Contact";
import { Footer } from "./Footer";
import { Navigation } from "./Navigation";
import "animate.css/animate.compat.css";
import { UseGetProducts } from "../hooks/useProducts";

const MainLayoutContainer = styled.div``;

export const MainLayout = ({ children, showContact = true }: { children: any, showContact?: boolean }) => {
    UseGetProducts();
    return (
        <MainLayoutContainer>
            <Navigation />

            {children}
            {showContact && <Contact />}
            <Footer />
        </MainLayoutContainer>
    );
}