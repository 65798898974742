import axios from 'axios';
import { api } from '../config/config';

export interface IContact{
    email: string;
    message: string;
}

export interface IResponse {
    count: number;
    data: any[];
}

export interface ILanguage{
    [value: string]: string;
}

export interface ICategories{
    level: number;
    name: ILanguage;
    parentTree: ICategories[];
    showMainSite: boolean;
    parent?: string;
    _id: string;
    value: string;
    label: string;
}


export const sendMessage = ({ email, message}: IContact) => {
    return axios.post(`${api.server}/leads/${api.credentials.leads}?apikey=${api.key}`, {
        email,
        message,
        name: email
    });
}