import { FunctionComponent } from "react";
import { MainLayout } from "../components/MainLayout";
import { MainCarrousel } from "../components/MainCarrousel";

export const ContactUs: FunctionComponent = () => {
    return (
        <MainLayout>
            <MainCarrousel tag='contact' />
        </MainLayout>
    );
}