import { FunctionComponent, useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import AnimateHeight from 'react-animate-height';
import { useTranslation } from 'react-i18next';
import { getValueByLang } from '../utils/products';
import { useRecoilValue } from 'recoil';
import { languageState } from '../states/state';

export interface Items {
  value: any;
  label: string | Record<string, string>;
}

export interface ICustomSelect {
  value?: string;
  onChange?: (value: any) => void;
  label: Items['label'];
  defaultValue?: string;
  children?: any;
  items?: Items[];
}
const CustomSelectWrapper = styled.div``;

const CustomInputWrapper = styled.div`
  border-bottom: 1px solid ${(p) => p.theme.colors.primary};
`;
const CustomSelectValue = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  cursor: pointer;
  p {
    flex: 1;
    color: ${(p) => p.theme.colors.gold};
    margin: 5px 0;
  }
  svg {
    flex: none;
  }
`;

const CustomSelectItem = styled.div`
  padding: 5px 0;
  border-bottom: 1px solid ${(p) => p.theme.colors.primary};
  text-align: right;
  cursor: pointer;
`;

export const CustomSelect: FunctionComponent<ICustomSelect> = ({ value, onChange, label, items }) => {
  const [expanded, setExpanded] = useState(false);
  const [currentValue, setCurrentValue] = useState(value);
  const lang = useRecoilValue(languageState);
  useEffect(() => {
    setCurrentValue(value);
  }, [value]);
  const toggle = useCallback(() => setExpanded(!expanded), [expanded]);
  const handleClick = useCallback(
    (value?: string) => {
      if (onChange) onChange(value);
      toggle();
    },
    [onChange, toggle]
  );
  const { t } = useTranslation();
  return (
    <CustomSelectWrapper>
      <CustomInputWrapper>
        <CustomSelectValue onClick={toggle}>
          <p>
            {getValueByLang(
              items?.find((i) => {
                return i.value === currentValue;
              })?.label || label,
              lang
            )}
          </p>
          {expanded ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
        </CustomSelectValue>
      </CustomInputWrapper>
      <AnimateHeight height={expanded ? 'auto' : 0} duration={500}>
        {items?.map((i) => (
          <CustomSelectItem onClick={() => handleClick(i.value)}>{getValueByLang(i.label, lang)}</CustomSelectItem>
        ))}
        <CustomSelectItem onClick={() => handleClick(undefined)}>{t('all')}</CustomSelectItem>
      </AnimateHeight>
    </CustomSelectWrapper>
  );
};
