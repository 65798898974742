/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Typography } from '@mui/material';
import { FunctionComponent, useCallback, useMemo, useState } from 'react';
import styled from 'styled-components';
import logo from '../logo.png';
import AddIcon from '@mui/icons-material/Add';
import { useNavigate } from 'react-router-dom';
import {
  filterProducts,
  filtersState,
  IFilters,
  IProducts,
  languageState,
  productsState,
  variantsState,
} from '../states/state';
import { useRecoilValue } from 'recoil';
import { getLocation, getMainImage, getState, getValueByLang } from '../utils/products';
import { useTranslation } from 'react-i18next';

export interface IPropertiesCatalog {
  view: 'card' | 'product';
}

export const PropertiesCatalog: FunctionComponent<IPropertiesCatalog> = ({ view = 'card' }) => {
  const properties: IProducts[] = useRecoilValue(productsState);
  const filters: IFilters = useRecoilValue(filtersState);
  const variants: IProducts[] = useRecoilValue(variantsState);
  const lang = useRecoilValue(languageState);

  const propertiesList = useMemo(() => {
    if (Object.keys(filters).length) {
      return filterProducts(properties, filters);
    } else {
      return properties;
    }
  }, [filters, lang, properties, variants]);

  const propertiesCards = useMemo(() => {
    return propertiesList.map((i) => {
      return view === 'card' ? <CatalogItem {...i}></CatalogItem> : <PropertiesListItem {...i} />;
    });
  }, [propertiesList, view]);

  return <>{propertiesCards}</>;
};

const CatalogImageContainer = styled.div<{ $full: boolean }>`
  clip-path: polygon(0 0, 100% 0, 75% 100%, 0% 100%);
  flex: 1;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background-color: ${(p) => p.theme.colors.black};
  padding-left: ${(p) => (p.$full ? '0' : '17%')};
`;

const CatalogImage = styled.img<{ $full: boolean }>`
  width: ${(p) => (p.$full ? '100%' : '150px')};
  object-fit: cover;
`;
const CatalogDescription = styled.div`
  text-align: right;
  padding-right: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
  gap: 5px;
  background-color: white;
  padding-bottom: 10px;
  * {
    margin: 0;
  }
  p {
    font-size: 12px;
    cursor: pointer;
    color: ${(p) => p.theme.colors.gold};
  }
  h4 {
    color: ${(p) => p.theme.colors.dark};
    text-transform: uppercase;
    font-weight: normal;
  }
`;
const CatalogCard = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: stretch;
  box-shadow: ${(p) => p.theme.shadow.primary};
  height: 150px;
  cursor: pointer;
  position: relative;
  background-color: white;
`;

const CatalogHover = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: ${(p) => p.theme.colors.dark};
  opacity: 0;
  color: white;
  gap: 5px;
  h4 {
    margin: 0;
    color: white;
    text-transform: uppercase;
  }
  &:hover {
    opacity: 0.85;
  }
  transition: 0.5s ease;
`;

const CatalogItem: FunctionComponent<IProducts> = ({ attributes, gallery, name, _id }) => {
  const navigate = useNavigate();
  const [imgHasError, setImageHasError] = useState(false);
  const handleClick = () => {
    navigate(`/properties/details/${_id}`);
  };

  const mainImgUrl = useMemo(() => getMainImage(gallery), [gallery]);
  const isFullImage = useMemo(() => !!(mainImgUrl && !imgHasError), [imgHasError, mainImgUrl]);
  const lang = useRecoilValue(languageState);
  const { t } = useTranslation();
  const state = useMemo(() => getState(attributes), [attributes]);
  const stateComponent = useMemo(() => {
    switch (state?.value) {
      case 'COMING':
        return {
          label: getValueByLang(state?.name, lang).toUpperCase().replace(' ', '<br />'),
          color: `light`,
        };
      case 'CONSTRUCTION':
        return {
          label: getValueByLang(state?.name, lang).toUpperCase().replace(' ', '<br />'),
          color: `dark`,
        };
      case 'LEASING':
        return {
          label: getValueByLang(state?.name, lang).toUpperCase().replace(' ', '<br />'),
          color: `yellow`,
        };
      default:
        break;
    }
  }, [state, t]);

  return (
    <CatalogCard
      onClick={handleClick}
      sx={{
        width: {
          xs: '100%',
          md: '45%',
        },
      }}
    >
      <CatalogImageContainer $full={isFullImage}>
        <CatalogImage
          $full={isFullImage}
          src={imgHasError ? logo : mainImgUrl || logo}
          onError={() => {
            setImageHasError(true);
          }}
          alt='catalog_item'
        />
      </CatalogImageContainer>
      <CatalogDescription>
        <PropertiesListItemState
          $bg={stateComponent?.color}
          dangerouslySetInnerHTML={{
            __html: stateComponent?.label || '',
          }}
        ></PropertiesListItemState>
        <h4>{getValueByLang(name, lang)}</h4>
        <p>{t('seeMore')}</p>
      </CatalogDescription>
      <CatalogHover>
        <h4>{getValueByLang(name, lang)}</h4>
        <AddIcon />
      </CatalogHover>
    </CatalogCard>
  );
};

////////////////////////////////////////////////////////////////////
const PropertiesListItemWrapper = styled.div`
  height: 450px;
  display: flex;
  box-shadow: ${(p) => p.theme.shadow.primary};
  width: 350px;
  flex-direction: column;
  cursor: pointer;
  background-color: white;
`;
const PropertiesListItemDescription = styled.div`
  padding: ${(p) => p.theme.padding.section};
  flex: 0.3;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  position: relative;
`;
const PropertiesListItemImageWrapper = styled.div<{ $full: boolean }>`
  height: 300px;
  background-color: ${(p) => p.theme.colors.dark};
  clip-path: polygon(0 0, 100% 0, 100% 84%, 0% 100%);
  align-items: center;
  justify-content: center;
  display: flex;
  img {
    width: ${(p) => (p.$full ? '100%' : '80%')};
    height: ${(p) => (p.$full ? '100%' : 'auto')};
    object-fit: cover;
  }
`;
const PropertiesListItemTitle = styled(Typography)``;
const PropertiesListItemLocation = styled(Typography)`
  color: ${(p) => p.theme.colors.main};
  font-weight: bold;
`;

export const PropertiesListItemState = styled.div<{ $bg?: string; $skipMargin?: boolean; $skipFlag?: boolean }>`
  position: absolute;
  right: 0;
  top: 0;
  font-size: 9px;
  width: 10rem;
  text-align: right;
  padding: ${(p) => (p.$skipFlag ? '20px' : '4px')} 4px 15px 10px;
  clip-path: polygon(100% ${(p) => (p.$skipFlag ? '10%' : '0%')}, 0 ${(p) => (p.$skipFlag ? '50%' : '0%')}, 100% 100%);
  height: 60px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: ${(p) => (p.$skipFlag ? 'center' : 'flex-start')};
  background-color: ${(p) => (p.$bg ? p.theme.colors[p.$bg] : `none`)};
  color: white;
  word-break: break-all;
  margin-top: ${(p) => (p.$skipMargin ? '-3.5rem' : '0')};
  font-weight: bold;
`;

const PropertiesItemImage = styled.img``;

export const PropertiesListItem: FunctionComponent<IProducts> = ({ name, _id, gallery, attributes }) => {
  const [imgHasError, setImageHasError] = useState(false);
  const mainImgUrl = useMemo(() => getMainImage(gallery), [gallery]);
  const isFullImage = useMemo(() => !!(mainImgUrl && !imgHasError), [imgHasError, mainImgUrl]);
  const lang = useRecoilValue(languageState);
  const { t } = useTranslation();

  const image = useMemo(() => {
    return (
      <PropertiesItemImage
        src={imgHasError ? logo : mainImgUrl || logo}
        onError={() => {
          setImageHasError(true);
        }}
        alt='catalog_item'
      />
    );
  }, [imgHasError, mainImgUrl]);

  const navigate = useNavigate();

  const location = useMemo(() => getLocation(attributes), [attributes]);
  const state = useMemo(() => getState(attributes), [attributes]);
  const stateComponent = useMemo(() => {
    switch (state?.value) {
      case 'COMING':
        return {
          label: getValueByLang(state?.name, lang).toUpperCase().replace(' ', '<br />'),
          color: `light`,
        };
      case 'CONSTRUCTION':
        return {
          label: getValueByLang(state?.name, lang).toUpperCase().replace(' ', '<br />'),
          color: `dark`,
        };
      case 'LEASING':
        return {
          label: getValueByLang(state?.name, lang).toUpperCase().replace(' ', '<br />'),
          color: `yellow`,
        };
      default:
        break;
    }
  }, [state, t]);

  const handleClick = useCallback(() => {
    navigate(`/properties/details/${_id}`);
  }, [navigate, _id]);

  return (
    <PropertiesListItemWrapper onClick={handleClick}>
      <PropertiesListItemImageWrapper $full={isFullImage}> {image}</PropertiesListItemImageWrapper>
      <PropertiesListItemDescription>
        <PropertiesListItemState
          $bg={stateComponent?.color}
          $skipFlag={true}
          $skipMargin={true}
          dangerouslySetInnerHTML={{
            __html: stateComponent?.label || '',
          }}
        ></PropertiesListItemState>
        <PropertiesListItemTitle variant='h2'>{getValueByLang(name, lang)?.toUpperCase()}</PropertiesListItemTitle>
        <PropertiesListItemLocation variant='h3'>{getValueByLang(location?.name, lang)?.toUpperCase()}</PropertiesListItemLocation>
      </PropertiesListItemDescription>
    </PropertiesListItemWrapper>
  );
};
