import { Box, Typography } from "@mui/material";
import styled from "styled-components"
import { Filters } from "./Filters";
import ScrollAnimation from "react-animate-on-scroll";
import { PropertiesCatalog } from "./PropertiesCatalog";
import bgHome from '../imgs/home-bg.png';
import { useTranslation } from "react-i18next";

const HomeCatalogContainer = styled.section`
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    background-image: url(${bgHome});
    background-size: cover;
`;

const Content = styled.section`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;


const CatalogHeader = styled(Typography)`
    text-align: center;
    padding: 30px 0;
    color: white;
    text-transform: uppercase;
`;


export const HomeCatalogDescription = styled(Box)`
    padding: 20px 0;
    padding-right: 20vw;
`;

const HomeCatalogDescriptionHeader = styled(Typography)`
    color: ${(p) => p.theme.colors.dark}
`;

const HomeCatalogDescriptionContent = styled.p`
`;


const Catalog = styled(Box)`
    width: 100vw;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    gap: 20px;
`;

const StyledScrollAnimation = styled(ScrollAnimation)`
    width: 100vw;
    display: grid;
    flex-wrap: wrap;
    gap: 15px;
    > div{
        width: 100%;
    }
`;

export const HomeCatalog = () => {
    const {t} = useTranslation();
    return (
        <HomeCatalogContainer>
            <Content>
                <CatalogHeader variant='h2'>
                    {t('explorePortfolio')}
                </CatalogHeader>
                <Filters showMore={true} />
                <HomeCatalogDescription sx={{
                    width: {
                        xs: "95vw",
                        md: "60vw"
                    },
                    marginLeft:{
                        xs: '30px',
                        md: '-12vw'
                    },
                    marginTop:{
                        xs: '50px',
                        md: '80px',
                    }
                }}>
                    <ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
                        <HomeCatalogDescriptionHeader variant='h3'>
                            {t('inspiring')}
                        </HomeCatalogDescriptionHeader>
                    </ScrollAnimation>
                    <ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
                        <HomeCatalogDescriptionContent>
                            {t('inspiringDetails')}
                        </HomeCatalogDescriptionContent>
                    </ScrollAnimation>
                </HomeCatalogDescription>
            </Content>

            <Catalog sx={{
                padding: {
                    xs: '1rem',
                    md: "0 10vw 7rem 10vw;"
                },
                "> div": {
                    gridTemplateColumns: {
                        xs: "repeat(1, 1fr)",
                        md: "repeat(2, 1fr)"
                    }
                }
            }}>
                <StyledScrollAnimation animateIn="fadeInUp" animateOnce={true} style={{
                    paddingTop: "3rem",
                }}>
                    <PropertiesCatalog view="card" /> 
                </StyledScrollAnimation>
            </Catalog>

        </HomeCatalogContainer>
    )
}
