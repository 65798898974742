import { FunctionComponent, useCallback, useMemo, useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import MenuIcon from '@mui/icons-material/Menu';
import { styled } from 'styled-components';
import { Logo } from './Logo';
// import SearchIcon from '@mui/icons-material/Search';
import { useLocation, useNavigate } from 'react-router-dom';
import { config } from '../config/config';
// import { SearchBar } from './SearchBar';
import * as flags from 'country-flag-icons/react/3x2';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem/MenuItem';
import { useRecoilState, useRecoilValue } from 'recoil';
import { availableLangsState, languageState } from '../states/state';
import { useTranslation } from 'react-i18next';

const drawerWidth = 240;

const StyledAppBar = styled(AppBar)`
  background-color: ${(p) => p.theme.colors.black};
  padding: 10px 24px;
  opacity: 0.85;
`;

const StyledToolbar = styled(Toolbar)`
  display: flex;
  align-items: center;
  position: relative;
`;

const StyledToolbarItem = styled(Button) <{ $active?: boolean }>`
  margin: 0 1rem;
  color: ${(p) => p.theme.colors.main};
  font-size: 0.85rem;
  display: inline-block;
  &::after {
    content: '';
    display: block;
    width: ${(p) => (p.$active ? `50%` : `0`)};
    height: 2px;
    background: ${(p) => p.theme.colors.main};
    transition: width 0.3s;
  }
  &:hover {
    background-color: transparent;
  }

  &:hover::after {
    width: 100%;
    transition: width 0.3s;
  }
`;

const StyledBox = styled(Box)`
  align-items: center;
`;

// const StyledSearchIcon = styled(SearchIcon)`
//   cursor: pointer;
//   fill: ${(p) => p.theme.colors.main};
// `;

const FlagContainer = styled.div`
  width: 20px;
  height: 20px;
`;

export const Navigation: FunctionComponent = () => {
  const [mobileOpen, setMobileOpen] = useState(false);
  const availableLangs = useRecoilValue(availableLangsState);
  const [selectedLang, setSelectedLang] = useRecoilState(languageState);
  // const [showSearcBar, setShowSearchBar] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const getPath = (path: string) => path.split('/')[1];
  const currentLocation = getPath(location.pathname);
  const { t, i18n } = useTranslation();

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const handleClick = (path: string, external: boolean) => {
    if (external) {
      window.open(path, '_blank');
    } else {
      navigate(path);
    }
  };

  const navItems = useMemo(() => {
    return config.navigation.filter((n) => n.title && !n.hideHeader).map(n => {
      if (n.translationKey) {
        return {
          ...n,
          path: t(n.translationKey) || n.path,
        }
      }
      return n;
    });
  }, [t])

  const handleChange = useCallback(
    async (value: any) => {
      const lang = value.target.value;
      i18n.changeLanguage(lang.toLowerCase());
      return setSelectedLang(lang);
    },
    [i18n, setSelectedLang]
  );

  const getFlag = useCallback((lang: string) => {
    return (flags as any)[lang] as flags.FlagComponent;
  }, []);

  const langSelector = useMemo(() => {
    return (
      <Box
        sx={{
          paddingLeft: {
            md: '35px',
          },
          '.MuiOutlinedInput-notchedOutline': {
            border: 'none !important',
          }
        }}
      >
        <Select
          value={selectedLang || availableLangs[0]}
          onChange={handleChange}
          className='lang-selector'
          sx={{
            width: 30,
            height: 20,
            padding: '5px',
            border: 'none',
            color: '#fff',
            '& .MuiSvgIcon-root': {
              display: 'none',
            },
            '& .MuiSelect-select': {
              padding: 0,
            },
            '& *': {
              border: 'none',
            },
          }}
        >
          {availableLangs.map((lf, i) => {
            const Flag = getFlag(lf);
            return (
              <MenuItem value={lf} key={i}>
                <FlagContainer>
                  <Flag />
                </FlagContainer>
              </MenuItem>
            );
          })}
        </Select>
      </Box>
    );
  }, [availableLangs, getFlag, handleChange, selectedLang]);

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center', padding: '10px 0', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
      <Logo />
      <Divider />
      <List>
        {navItems.map((item) => (
          <ListItem key={item.title} disablePadding onClick={() => handleClick(item.path, !item.component)}>
            <ListItemButton sx={{ textAlign: 'center' }}>
              <ListItemText primary={t(item.title || '').toUpperCase()} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
      {langSelector}
    </Box>
  );
  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <StyledAppBar
        sx={{
          position: {
            xs: 'sticky',
            md: 'fixed',
          },
        }}
      >
        {/* <SearchBar open={showSearcBar} close={() => setShowSearchBar(false)} /> */}
        <StyledToolbar
          sx={{
            justifyContent: { xs: 'center', md: 'space-around' },
          }}
        >
          <IconButton
            color='inherit'
            aria-label='open drawer'
            edge='start'
            onClick={handleDrawerToggle}
            sx={{
              mr: 2,
              display: { md: 'none' },
              position: 'absolute',
              left: '10px',
            }}
          >
            <MenuIcon />
          </IconButton>
          <Logo />
          <StyledBox
            sx={{
              display: { xs: 'none', md: 'flex' },
            }}
          >
            {navItems.map((item, i) => {
              const isActive = !!(currentLocation && getPath(item.path) === currentLocation);
              return (
                <StyledToolbarItem
                  key={`item.title${i}`}
                  onClick={() => handleClick(item.path, !item.component)}
                  $active={isActive}
                >
                  {t(item.title || '').toUpperCase()}
                </StyledToolbarItem>
              );
            })}
            {/* <StyledSearchIcon onClick={() => setShowSearchBar(true)} /> */}
            {langSelector}
          </StyledBox>
        </StyledToolbar>
      </StyledAppBar>
      <nav>
        <Drawer
          variant='temporary'
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
        >
          {drawer}
        </Drawer>
      </nav>
    </Box>
  );
};
