import { ReactNode } from 'react';
import { Home } from '../pages/Home';
import { About } from '../pages/About';
import { Properties } from '../pages/Properties';
import { ContactUs } from '../pages/Contact';
import { PropertiesDetails } from '../pages/PropertiesDetails';
import { Disclaimer } from '../pages/Disclaimer';
import { Terms } from '../pages/Terms';

export interface INavigation {
  path: string;
  title?: string;
  component?: ReactNode;
  hideHeader?: boolean;
  translationKey?: string;
}

export interface IConfig {
  navigation: INavigation[];
}

export const config: IConfig = {
  navigation: [
    {
      path: '/',
      title: '',
      component: <Home />,
    },
    {
      path: '/about',
      title: 'about',
      component: <About />,
    },
    {
      path: '/properties',
      title: 'properties',
      component: <Properties />,
    },
    {
      path: 'https://webbuilders.com.ar',
      title: 'apply',
      translationKey: 'apply-link',
    },
    {
      path: 'https://webbuilders.com.ar',
      title: 'residents',
      translationKey: 'residents-link',
    },
    {
      path: '/properties/details/:id',
      component: <PropertiesDetails />,
    },
    {
      path: '/contact',
      title: 'contact',
      component: <ContactUs />,
    },
    {
      path: '/disclaimer',
      title: 'disclaimer',
      component: <Disclaimer />,
      hideHeader: true,
    },
    {
      path: '/terms',
      title: 'terms',
      component: <Terms />,
      hideHeader: true,
    },
    {
        path: '/:id',
        component: <PropertiesDetails />,
    },
  ],
};

export const api = {
  server: 'https://api.wbsuite.app/api/v2',
  key: 'kc1g3s9EJSvwc59dpf~tw999L3Fq3o4X',
  credentials: {
    leads: '6665fcecf9b0ebfa2ac39888',
  },
  img: 'https://api.wbsuite.app/public',
  googleMapsApi: 'AIzaSyDYyNxI97cnHgwFW42WW4Dy2F_-zGWvAu8',
  recaptcha: '6LehyCMqAAAAACJuBoJlQeO0vKD5S7tO0gMiU4wm',
};
