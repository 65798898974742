import { FunctionComponent } from "react";
import styled from "styled-components";
import About1 from '../imgs/about1.png';
import About2 from '../imgs/about2.png';
import { Box, Typography } from "@mui/material";
import { theme } from "../App";
import ScrollAnimation from "react-animate-on-scroll";
import { useTranslation } from "react-i18next";
import { MainCarrousel } from "./MainCarrousel";

const AboutSection = styled.section``;
const AboutUs = styled.div``;
const AboutUsTitle = styled(Typography)`
    padding-bottom: 0;
`;
const AboutUsSubTitle = styled(Typography)`
    color: ${(p) => p.theme.colors.main};
    padding-bottom: 0;
    padding:top: 0;
`;
const AboutUsDescription = styled(Box)`
    p{
        width: 80%;
    }
`;
const AboutUsFirstSection = styled.div`
    display: flex;
    img{
        width: 100%;
    }
    *{
        flex: 1;
    }
`;
const AboutUsSecondSection = styled.div`
    display: flex;
    img{
        width: 100%;
    }
    *{
        flex: 1;
    }
    .description{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
`;
const AboutUsImg = styled.img``;
const AboutUsImgWrapper = styled(Box)``;

export const AboutUsSection: FunctionComponent = () => {
    const { t } = useTranslation();
    return (
        <AboutSection>
            <MainCarrousel tag='about' />
            <AboutUs>
                <AboutUsTitle variant="h1" sx={{
                    padding: {
                        xs: theme.padding.responsiveSection,
                        md: theme.padding.title,
                    }
                }}>{t('aboutUs')}</AboutUsTitle>
                    <AboutUsSubTitle variant="h3" sx={{
                        padding: {
                            xs: theme.padding.responsiveSection,
                            md: theme.padding.title,
                        }
                    }}>{t('modenaHousing')}</AboutUsSubTitle>
                    <AboutUsFirstSection>
                        <AboutUsDescription className="description" sx={{
                            padding: {
                                xs: theme.padding.responsiveSection,
                                md: theme.padding.section,
                            },
                            paddingLeft: {
                                xs: '2rem',
                                md: '10rem'
                            }
                        }}>
                            <p dangerouslySetInnerHTML={{ __html: t('modenaHousingAbout1')}} />
                            <p>
                            {t('modenaHousingAbout2')}
                            </p>
                        </AboutUsDescription>
                        <AboutUsImgWrapper sx={{
                            display: {
                                xs: 'none',
                                md: 'block'
                            }
                        }}><AboutUsImg src={About1} /></AboutUsImgWrapper>
                    </AboutUsFirstSection>
                <ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
                    <AboutUsSecondSection>
                        <AboutUsImgWrapper sx={{
                            display: {
                                xs: 'none',
                                md: 'block'
                            }
                        }}><AboutUsImg src={About2} /></AboutUsImgWrapper>
                        <AboutUsDescription className="description" sx={{
                            paddingLeft: {
                                xs: '2rem',
                                md: '10rem'
                            }
                        }}>
                            <p>
                            {t('modenaHousingAbout3')}
                            </p>
                            <p>
                            {t('modenaHousingAbout4')}
                            </p>
                            <p>
                            {t('modenaHousingAbout5')}
                            </p>
                        </AboutUsDescription>
                    </AboutUsSecondSection>
                </ScrollAnimation>
            </AboutUs>
        </AboutSection>
    );
}