import { getAttributeChoiceValue, getFloorplans, getImageUrl, getLink, getPDFLink, getValueByLang } from '../utils/products';
import currencyFormatter from 'currency-formatter';
import { IAttribute, IProducts, languageState } from '../states/state';
import { useRecoilValue } from 'recoil';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DownloadOutlined } from '@mui/icons-material';
import PictureAsPdfOutlinedIcon from '@mui/icons-material/PictureAsPdfOutlined';
import { Carrousel } from './Carrousel';

const filterImagesBy = (gallery: IProducts['gallery'], tag: string) => {
  return gallery.filter((d) => d.document.tags.includes(tag));
};

const getMainImage = (gallery: IProducts['gallery']) => {
  return gallery.filter((d) => !!d.main);
};

export const UnitCard = ({ unit }: { unit: IProducts }) => {
  const { t } = useTranslation();
  const lang = useRecoilValue(languageState);
  const [cardSlider, setCardSlider] = useState<string[]>([]);

  useEffect(() => {
    const mainImg = getMainImage(unit.gallery);
    const mapSlider = filterImagesBy(unit.gallery, 'plan').filter((i) => !i.main);
    const unitSlider = filterImagesBy(unit.gallery, 'unit').filter((i) => !i.main);
    setCardSlider(
      (mainImg ? mainImg : [])
        .concat([...unitSlider, ...mapSlider])
        .map((s) => getImageUrl(s.document.file.filename) as string)
    );
  }, [unit]);
  return (
    <div key={unit._id} className='group relative'>
      <div className='aspect-h-1 aspect-w-1 w-full overflow-hidden rounded-md bg-gray-200 lg:aspect-none lg:h-80'>
        <Carrousel imgs={cardSlider} autoplay={false} main={false} navButtonsAlwaysVisible={true}/>
      </div>
      <div className='mt-4 flex justify-between items-center'>
        <h3 className='text-sm text-gray-700'>
          {getValueByLang(unit.name, lang)}
        </h3>
        <div>
        {getPDFLink(unit.attributes) && (
            <button
            type='button'
            className='relative bg-gray-800 p-1 text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800'
            onClick={() => window.open(getPDFLink(unit.attributes) as string, '_blank')}
          >
            <PictureAsPdfOutlinedIcon />
          </button>
          )}
          {!!getFloorplans(unit.attributes) && (
            <button
              type='button'
              className='relative bg-gray-800 p-1 text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800'
              onClick={() => window.open(getFloorplans(unit.attributes) as string, '_blank')}
            >
              <DownloadOutlined />
            </button>
          )}
        </div>
      </div>
      <div>
        {unit?.attributes
          .filter((attr) => !new RegExp(attr.attribute.key, 'i').test('(link|floor-plans|link_pdf)'))
          .map((attr) => {
            const attrValue = getValueByLang(getAttributeChoiceValue(attr as IAttribute)?.name, lang) || attr.value;
            if (+attrValue || isNaN(+attrValue)) {
              return (
                <div className='mt-2 flex items-center text-sm text-gray-500 gap-[.5rem]'>
                  <span className='font-bold'>{getValueByLang(attr.attribute.name, lang)}</span>
                  <span>{attrValue}</span>
                </div>
              );
            } else {
              return <></>;
            }
          })}
      </div>
      {!!+(unit?.price || 0) && (
        <div className='mt-2 flex items-center text-sm text-gray-500 gap-[.5rem]'>
          <span className='font-bold'>{t('pricesFrom')}</span>
          <span className='italic'>{currencyFormatter.format(unit.price || 0, { code: 'USD' })}</span>
        </div>
      )}
      {getLink(unit.attributes) ? (
        <a
          href={getLink(unit.attributes) as string}
          target='_blank'
          className='mt-5 block w-full bg-gold px-3 py-2 text-center text-sm font-semibold text-white shadow-sm group-hover:opacity-75 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600'
          rel='noreferrer'
        >
          {t('applyNow')}
        </a>
      ) : (
        <></>
      )}
    </div>
  );
};
